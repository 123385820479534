@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  /* border: 1px solid; */
}

@keyframes revealAndColor {
    from {
        width: 0;
        color: white;
    }
    to {
        width: 100%;
        color: #00FF00; /* Bright green */
    }
}

.verified {
    animation: revealAndColor 3s forwards;
    white-space: nowrap;
    overflow: hidden;
    animation-delay: var(--delay, 0s); /* Default delay is 0s unless specified */
}
